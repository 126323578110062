const INVALID_URLS = ['null', '#not_implemented'];
const DEFAULT_FALLBACK = '#';

/**
 * Transforms a URL string by replacing application URL placeholders with actual URLs
 * @param {string} linkUrl - The URL string to transform
 * @returns {string} - The transformed URL
 */
export const getUrlFromLink = (linkUrl) => {
  if (!linkUrl || INVALID_URLS.includes(linkUrl)) {
    return DEFAULT_FALLBACK;
  }

  const { appURLs = {} } = window.AFP_CONFIG || {};
  const appUrlRegex = /\${appURLs\.([^}]+)}/;
  const match = linkUrl.match(appUrlRegex);

  if (!match?.[1]) {
    return linkUrl;
  }

  const appKey = match[1];
  return appURLs[appKey] ? linkUrl.replace(match[0], appURLs[appKey]) : linkUrl;
};

const updateLinkUrl = (menuItem) => ({
  ...menuItem,
  linkUrl: getUrlFromLink(menuItem.linkUrl),
});

export const getAvailableMenuItemsForUser = (menu, ability) => {
  const filteredMenu = menu.filter(
    (item) =>
      !item?.isHidden &&
      (ability.can(
        item?.permission?.operation?.name,
        item?.permission?.subject?.name,
      ) ||
        !item?.permission),
  );

  return filteredMenu
    .map(({ childNavigationItems, linkUrl, ...menuItem }) => {
      const availableChildNavigationItems = [];

      if (childNavigationItems?.length > 0) {
        childNavigationItems.map((childMenuItem) => {
          if (childMenuItem.permission) {
            if (
              ability.can(
                childMenuItem.permission?.operation.name,
                childMenuItem.permission?.subject.name,
              )
            ) {
              availableChildNavigationItems.push(updateLinkUrl(childMenuItem));
            }
          } else {
            availableChildNavigationItems.push(updateLinkUrl(childMenuItem));
          }
          return availableChildNavigationItems;
        });
      }
      return {
        ...menuItem,
        linkUrl: getUrlFromLink(linkUrl),
        childNavigationItems: availableChildNavigationItems,
      };
    })
    .filter(
      (item) =>
        !item.hasSubMenu ||
        (item.hasSubMenu && item.childNavigationItems.length > 0),
    );
};

export default {
  getAvailableMenuItemsForUser,
  getUrlFromLink,
};
